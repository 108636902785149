<template>
  <div >
    <!--<TitleComponent class="primary--text text-uppercase text-center" :title="`Maps`"/>-->
    <PageHeader pagetitle="TOURISM" :img="img" />   
    <!--<v-container :class="$vuetify.breakpoint.lgAndUp ? 'small-container': ''">-->
    
      
   
  <v-row>
      <v-col  class="d-flex child-flex" cols="12" >
      <v-card :width="$vuetify.breakpoint.mdAndUp ? 350 : 175" elevation="10" cols="12">
          <!--  -->
        <v-navigation-drawer permanent  cols="12"  :width="$vuetify.breakpoint.mdAndUp ? 350 : 175">
          
            <v-list nav>
              <v-list-item-group  active-class="border" color="indigo"> 
                <template v-for="(itemcat, i) in this.TableCategoryData"
                >
                <v-divider :key="i" v-show="i !== 0"> </v-divider>
                <v-list-item link v-model="SelectedCategory" @click="UpdateSelectedCategory(itemcat.Name)" :key="i"  dark  >
                  <v-list-item-icon>
                    <v-icon color="blue">mdi-{{itemcat.Icon}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="blue--text text--darken-2">{{itemcat.Name}}</v-list-item-title>
                  <v-divider inset></v-divider>
                </v-list-item>
                <v-divider :key="i" v-show="i !== 0"> </v-divider>
                </template>
              </v-list-item-group>  
            </v-list>
        </v-navigation-drawer>
      </v-card> 

      <v-container>

    
    <swiper ref="mySwiper" class="swiper" :options="swiperOptions">    
      <swiper-slide
        v-for="(item, i) in this.TableData"   
        :key="i"
      >
        <v-card
          class="justify-center mx-auto"
          :max-height="$vuetify.breakpoint.mdAndUp ? 450 : 125"
          :height="$vuetify.breakpoint.mdAndUp ? 400 : 125"
          :max-width="$vuetify.breakpoint.mdAndUp ? 650 : 175"
          :width="$vuetify.breakpoint.mdAndUp ? 450 : 125"
          elevation="15"
          hover
          outlined
        >
        <!-- 
          <v-img
              class=""
              :height="$vuetify.breakpoint.lgAndUp ? 250 : 100"
              :width="$vuetify.breakpoint.lgAndUp ? 350 : 200"
              :src="$api_url+item.Url"
              :lazy-src="$api_url+item.Url"
              aspect-ratio=""
              :alt="item.Name"
              :title="item.Name"
          >
        
          </v-img>
        -->
            
          <v-card-title class="text-justify text-subtitle-1 text-uppercase font-weight-bold text-wrap" >
          <a @click="$router.push('/viewcontent/'+item.ID+'/tourism')">
            {{item.Name}}
          </a>
          </v-card-title> 
          <v-card-subtitle class="font-weight-bold">
            {{item.Address}}
          </v-card-subtitle>
          <v-card-subtitle class="text-caption black--text text-justify">
            {{item.Content}}
          </v-card-subtitle> 
        
          <EnlargeableImage
            
            max-height="250"
            max-width="500"
            :src="$api_url+item.Url" 
            :src_large="$api_url+item.Url" 
            :lazy-src="$api_url+item.Url"
            :src_height="$vuetify.breakpoint.lgAndUp ? 200 : 90"
            :src_width="$vuetify.breakpoint.lgAndUp ? 450 : 175"
          />
        </v-card>
           
        
         
         
        
        <v-divider></v-divider>   
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    </v-container>
    </v-col>
  </v-row>
 
    <Footer v-if="this.$route.name !== 'Home'"> </Footer>
  </div>
</template>

<script>
import { Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import axios from 'axios' 
//import TitleComponent from '@/components/TitleComponent'
import Footer from '@/components/Footer'

import EnlargeableImage from '@/components/EnlargeableImage';
import PageHeader from '@/components/PageHeader'


export default {

  name : 'Tourism',
  components: {
    //TitleComponent,
    Footer,
    Swiper,
    SwiperSlide,
    EnlargeableImage,
    PageHeader
   
  },
  //directives: {
  //  swiper: directive
  //},
  data () {
    return {
      show: false,
      img : 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
      items:[],
      TableData:[],
      TableCategoryData:[],
      SelectedCategory:'',
      swiperOptions: {
          slidesPerView: this.$vuetify.breakpoint.mdAndUp ? 3 : 1,
          slidesPerColumn: this.$vuetify.breakpoint.mdAndUp ? 2 : 1,
          spaceBetween: 5,
          pagination: {
            el: '.swiper-pagination',
          
            clickable: true
          }
          // Some Swiper option/callback...
        },
    }

    },

    watch: {
      // whenever question changes, this function will run
      SelectedCategory: function () {
        //alert(this.SelectedCategory)
        this.TableData=this.getAllRecords(`/getAllRecords/tourism/where tourismcategory='`+this.SelectedCategory+`'/Name asc`,`tourism`);
      },

    },
    mounted(){
      //alert(this.items);
      this.TableData=this.getAllRecords(`/getAllRecords/tourism/where ID > 0 /TourismCategory asc`,`tourism`);
      this.TableCategoryData=this.getAllRecords(`/getAllRecords/tourismcategory/where ID > 0 /Name asc`,`tourismcategory`);
      //alert(THIS.TableData);
     this.swiper.slideTo(3, 1000, false) 

    },  

    updated(){
      
     
    },

    computed: {
      swiper() {
        return this.$refs.mySwiper.$swiper
      }
    },

    methods:{

      UpdateSelectedCategory(SelectedCategory){
        this.SelectedCategory=SelectedCategory
      },

      toggleshow(item){
        item.Show=!item.Show
      },
      formatData(value){
        //alert(value);!isNaN(Date.parse(value)) 
        //alert(value)
        var findchar=value.toString().search('-');
        if ( !isNaN(parseFloat(value)) && (findchar < 0)) { 
            
            return Number(value).toLocaleString(undefined,{minimumFractionDigits:2,maximumFractionDigits:2});
        }
        else if( !isNaN(Date.parse(new Date(value))) ){
            
            return this.formatDate(value);
        }
        else{
            return value
        }
      },
      getAllRecords:function(query,table){
      axios.get( this.$api_url+query,{
          
        params: {
              
          }
      })
      .then(response => {
          //alert(Object.keys(response.data).length);
          if (Object.keys(response.data).length > 0) {
              //alert(response.data);
              if(table=='tourism'){
                this.TableData=response.data;
              }
              else if(table=='tourismcategory'){
                this.TableCategoryData=response.data
              }
              
            }
      })
      .catch(error => { console.log(error)})
    },  
  }
}

</script>

<style lang="scss" >
  @import 'swiper/swiper.scss';
   .swiper {
    height: 1000px;
    margin-left: auto;
    margin-right: auto;

    .swiper-slide {
      height: 450px;
    }
  };
 
.border {
  border: 2px dashed orange;
}

</style>